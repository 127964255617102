import React, { Fragment, useContext, useEffect } from "react";
import { Mui, PageHeadingContext } from '@osu/react-ui'
import { useLogoutQuery } from "../../services/authorization/api";
import Informational from "../../Common/components/Informational";
import { APPLICATION_NAME, STATUSES } from "../../util/constants";
import { useSelector } from "react-redux";
import { selectAuthenticationStatus } from "../../services/authorization/slices";

const clearKnowledgeArticle = 'https://osuitsm.service-now.com/selfservice/kb_view.do?sys_kb_id=c591b3680a0a3c05014c37aec7538c29'

const LogOut = () => {
  useLogoutQuery();
  const { setHeading, setPageTitle } = useContext(PageHeadingContext);
  const authStatus = useSelector(selectAuthenticationStatus);
  const isLoading = authStatus === STATUSES.LOADING
  const info = isLoading ? `Signing out of ${APPLICATION_NAME}` :  `You have been signed out of ${APPLICATION_NAME}.`

  useEffect(() => {
    setHeading('Sign Out');
    setPageTitle('Sign Out');
    return () => {
      setHeading("");
      setPageTitle("");
    };
  }, [setHeading, setPageTitle, info]);

  const alertProps = {
    component: Mui.Alert,
    sx: theme => {
      const blue = theme.palette.info.main
      return {
        border: `solid 2px ${blue}`
      }
    },
    severity: 'info',
    className: 'margin-top-1'
  }

  return (
    <Fragment>
        {
          <Informational info={info} childrenFirst={true} className="padding-x-0 padding-bottom-1" infoComponent={isLoading ? "h2" : "body1"}>
           {isLoading &&<Mui.Box className="margin-right-4">
               <Mui.CircularProgress />
            </Mui.Box>}
        </Informational>
        }
        <Mui.Box role="alert" {...isLoading ? {} : alertProps}>
          {!isLoading && <Fragment>
            <Mui.AlertTitle sx={theme => {
            const blue = theme.palette.info.main
            const darkMode = theme.palette.mode === 'dark'
            const fn = darkMode ? Mui.lighten : Mui.darken
            return {
              color: fn(blue, .5),
              fontWeight: theme.typography.fontWeightBold
            }
          }}>Clear your browser on a shared machine!</Mui.AlertTitle>
            You MUST <Mui.Link href={clearKnowledgeArticle}>clear your browsing history</Mui.Link> if this is a shared machine, or you risk improper access to any services to which you logged in recently. 
            Closing the browser will NOT prevent improper access in most cases!
          </Fragment>}
        </Mui.Box>
    </Fragment>
  );
};

export default LogOut;
