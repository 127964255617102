import { feedbackKeys, feedbackOptions, validations } from "./constants";

const baseMsg = `Please include a brief description`
const constructDescriptionError = (descriptionLength) => {
    const { minlength, maxlength } = validations[feedbackKeys.description]

    let descriptionError = "";
    if (!descriptionLength) {
      descriptionError = `${baseMsg}.`;
    } else if (descriptionLength && descriptionLength < minlength) {
      descriptionError = `${baseMsg} of at least ${minlength} characters.`;
    } else if (descriptionLength > maxlength) {
      descriptionError = `${baseMsg} less than ${maxlength} characters.`;
    }

    return descriptionError
}

const checkFormBeforeSubmitting = (values = {}) => {
  let errors = []
  const isGeneralFeedback = values[feedbackKeys.type] === feedbackOptions.general
  if(!isGeneralFeedback && !values[feedbackKeys.email]) {
    errors.push(`Please include a valid email address.`)
  }
  const descriptionError = constructDescriptionError((values[feedbackKeys.description] ?? "").length)
  if(descriptionError) {
    errors.push(descriptionError)
  }
  return errors
} 

export { constructDescriptionError, checkFormBeforeSubmitting }