import { useEffect, useRef } from "react";
import { Mui, usePrevious } from "@osu/react-ui";
import { severityOptions } from "../util/constants";

const messages = {
  [severityOptions.success]: 'Thank you! Your feedback has been submitted successfully.',
  [severityOptions.error]: 'Error - There was a problem submitting your feedback. Please review your form and submit again.',
  [severityOptions.info]: 'Submitting your feedback...',
}

const alertStyles = (theme, severity) => {
  const colors = theme.palette[severity]
  return {
    [`&:focus`]: {
      outline: `${colors.light ?? '-webkit-focus-ring-color'} double 4px`
    }
  }
}

const Alert = ({ severity: propsSeverity, overrideErrors }) => {
  const severity = overrideErrors?.length ? severityOptions.error : propsSeverity
  const description = overrideErrors?.length ? ("Error - " + overrideErrors.join(" ")) : (severity && messages[severity])
  const previousDescription = usePrevious(description)
  const previousSeverity = usePrevious(severity)
  const ref = useRef()
  
  let alertProps = {}
  if(severity && description) {
    alertProps = {
      component: Mui.Alert,
      role: 'alert',
      severity,
      className: 'margin-bottom-2',
      variant: 'outlined',
      sx: (theme) => alertStyles(theme, severity)
    }
  }

  useEffect(() => {
    const updateHappenedToDescription = !previousDescription && !!alertProps.component
    const changeInSeverity = alertProps.severity && (previousSeverity !== alertProps.severity)
    if(ref.current && (updateHappenedToDescription || changeInSeverity)) {
      ref.current.focus()
    }
  }, [alertProps.component, alertProps.severity, previousDescription, previousSeverity])

  return (
    <Mui.Box ref={ref} {...alertProps} tabIndex="-1">
      {description}
    </Mui.Box>
  );
};

export default Alert;
