import { Mui } from "@osu/react-ui";
import { feedbackTypes } from "../util/constants";

const label = 'How would you describe your feedback?'

function Select(props = {}) {
  const { id, value, onChange, disabled } = props;

  const labelId = id + "-label";
  return (
    <Mui.FormControl>
      <Mui.InputLabel sx={(theme) => {
        return {
          backgroundColor: theme.palette.background.default
        }
      }} id={labelId} htmlFor={id}>
        {label}
      </Mui.InputLabel>
      <Mui.Select
        native
        variant="outlined"
        value={value}
        labelId={labelId}
        id={id}
        label={label}
        onChange={onChange}
        inputProps={{
          disabled
        }}
      >
        {feedbackTypes.map((i, index) => {
          return (
            <option key={id + `${index}-${i.key}`} value={i.key}>
              {i.label}
            </option>
          );
        })}
      </Mui.Select>
    </Mui.FormControl>
  );
}

export default Select;
