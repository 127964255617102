import { Mui } from "@osu/react-ui";
import { useState } from "react";
import Select from "./Select";
import {
  feedbackOptions,
  feedbackKeys as keys,
} from "../util/constants";
import SubmitButton from "./SubmitButton";
import Description from "./Description";
import { constructDescriptionError } from "../util/functions";

function Form({ id, readOnly, formValues, onSubmit, onChange }) {
  const [descriptionFieldTouched, setDescriptionFieldTouched] = useState(false);
  const [missingEmail, setMissingEmail] = useState(false);
  const descriptionError = constructDescriptionError(
    (formValues[keys.description] ?? "").length
  );
  const emailIsRequired =
    formValues[keys.type] && formValues[keys.type] !== feedbackOptions.general;

  return (
    <Mui.Box
      component="form"
      id={id}
      onSubmit={onSubmit}
      className="display-flex flex-direction-column"
      sx={{
        [`& > *, > *.${Mui.formControlClasses.root}`]: {
          marginBottom: 2,
        },
      }}
    >
      <Select
        id={id + keys.type}
        value={formValues[keys.type]}
        onChange={(event) => {
          onChange(event, keys.type);
          const notGeneral =
            event.target.value &&
            event.target.value !== feedbackOptions.general;
          const missingEmailErrorState = notGeneral && !formValues[keys.email];
          setMissingEmail(missingEmailErrorState);
        }}
        disabled={readOnly}
      />
      <Mui.TextField
        label="Email"
        variant="outlined"
        id={id + keys.email}
        type="email"
        required={emailIsRequired}
        value={formValues[keys.email]}
        error={missingEmail}
        helperText={missingEmail && "Email is required."}
        onChange={(event) => {
          onChange(event, keys.email)
          setMissingEmail(emailIsRequired && !event.target.value)
        }}
        onBlur={() => {
          setMissingEmail(emailIsRequired && !formValues[keys.email])
        }}
        InputProps={{
          readOnly,
        }}
      />
      <Description
        id={id + keys.description}
        onChange={(event) => {
          onChange(event, keys.description);
        }}
        touched={descriptionFieldTouched}
        error={descriptionError}
        readOnly={readOnly}
        value={formValues[keys.description]}
        onBlur={() => {
          setDescriptionFieldTouched(true);
        }}
      />
      <SubmitButton id={id + 'submit'} disabled={!!(readOnly || descriptionError || missingEmail)} />
    </Mui.Box>
  );
}

export default Form;
