import React, { useContext, useEffect } from "react";
import { Mui, PageHeadingContext } from '@osu/react-ui';
import useSignIn from "../hooks/useSignIn";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuthenticationStatus } from "../../services/authorization/slices";
import { STATUSES } from "../../util/constants";
import Informational from "../../Common/components/Informational";
import LoadingLogin from "../../Common/components/Loading/Login";
import ExternalLink from "../../Common/components/ExternalLink";


function LogIn(props = {}) {
  const authStatus = useSelector(selectAuthenticationStatus);
  const { setHeading, setPageTitle } = useContext(PageHeadingContext);
  const { handleSignIn } = useSignIn()
  const location = useLocation()
  const redirectPath = location?.state?.redirectPath;
  
  useEffect(() => {
    setHeading('Sign In');
    setPageTitle('Sign In');
    return () => {
      setHeading("");
      setPageTitle("");
    };
  }, [setHeading, setPageTitle]);

  useEffect(()=>{
    if(authStatus !== STATUSES.COGNITO_FAILURE) {
      handleSignIn({redirectPath, doNotRedirect: false})
    }
  },[redirectPath, handleSignIn, authStatus])


  let loginComponent = (<LoadingLogin />)
  const LOGIN_LOADING_STATUSES = [STATUSES.IDLE, STATUSES.LOADING, STATUSES.SUCCESS]
  if( !(LOGIN_LOADING_STATUSES.includes(authStatus))){
    const info = (
      <>
        There was an error signing you in. 
        If this error continues contact us 
        at <ExternalLink href="http://help.osu.edu">help.osu.edu</ExternalLink>. 
      </>);
    loginComponent = (
      <Informational info={info} >
        <Mui.Button
          className="margin-left-2"
          variant="contained"
          color="primary"
          aria-label={`Retry signing in}`}
          onClick={() => handleSignIn({
            force: true
          })}
        >
          Sign In
        </Mui.Button>
      </Informational>
    ) 
  }
  
  return loginComponent;
}

export default LogIn;
