import { Mui } from "@osu/react-ui";
import { feedbackKeys, validations } from "../util/constants";

function Description({ id, value, onChange, readOnly, error: descriptionError, touched, ...rest }) {
  const minlength = validations[feedbackKeys.description].minlength;
  const maxlength = validations[feedbackKeys.description].maxlength;
  const descriptionLength = (value ?? "").length ?? 0;
  const charRange = ` ${descriptionLength} / ${maxlength} Characters`;

  const inError = touched && descriptionError


  return (
    <Mui.TextField
      {...rest}
      id={id}
      label="Description"
      variant="outlined"
      required
      error={inError}
      value={value}
      helperText={inError ? descriptionError : charRange}
      multiline
      rows={5}
      onChange={(event) => {
        onChange(event, descriptionError)
      }}
      InputProps={{
        readOnly,
        minlength,
        maxlength,
      }}
    />
  );
}

export default Description;
