import { Mui, MuiIcons } from "@osu/react-ui";

function SubmitButton({ disabled, id }) {
  let sx = (theme) => {
    let color = "rgba(0, 0, 0, 0.58)";
    const darkMode = theme.palette.mode === "dark";
    if (darkMode) {
      color = "rgba(255, 255, 255, 0.5)";
    }

    return {
      [`&#${id}.${Mui.buttonBaseClasses.disabled}`]: {
        pointerEvents: "none",
        color,
      },
    };
  };

  return (
    <Mui.Box
      sx={
        disabled
          ? {
              cursor: "not-allowed",
            }
          : {}
      }
      className="margin-left-auto"
    >
      <Mui.Button
        variant="contained"
        id={id}
        type="submit"
        startIcon={<MuiIcons.Send />}
        disabled={disabled}
        aria-disabled={disabled}
        sx={sx}
      >
        Submit
      </Mui.Button>
    </Mui.Box>
  );
}

export default SubmitButton;
