import { useEffect } from 'react';
import { Mui } from "@osu/react-ui";
import BuckeyeIcon from "../../../Assets/Images/Buckeye";
import BuckeyeWhite from "../../../Assets/Images/BuckeyeWhite";
import useXichigan from "../../hooks/useXichigan";

function BuckeyeLeaf() {
  const theme = Mui.useTheme();
  const darkMode = theme?.palette?.mode === "dark";
  const Icon = darkMode ? BuckeyeWhite : BuckeyeIcon;
  const { className, onClick } = useXichigan();

  useEffect(() => {
    const checkMichigan = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_CONTENT_V2 + "/athletics/game-day");
        const data = await response.json();
        const containsMichigan = JSON.stringify(data.data).toLowerCase().includes("michigan");

        if (containsMichigan) {
          onClick(); 
        }
      } catch (error) {
        console.error('Error checking Michigan status:', error);
      }
    };

    checkMichigan();
    // the following line removes the warning for the empty dependency list, done here because useXichigan is mutable and maintains its own state, so onClick is not safe to useEffect on more than once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Mui.IconButton
      sx={{ marginLeft: "auto" }}
      aria-label={`Toggle Easter Egg`}
      className={className}
      onClick={onClick}
    >
      <Icon
        sx={{
          fontSize: "inherit",
        }}
      />
    </Mui.IconButton>
  );
}

export default BuckeyeLeaf;