const feedbackOptions = {
  general: 'general',
  accessibility: 'accessibility',
  bug: 'bug',
  feature: 'feature',
  partnership: 'partnership',
}

const feedbackTypes = [
  {
    label: "General",
    key: feedbackOptions.general
  },
  {
    label: "Accessibility",
    key: feedbackOptions.accessibility
  },
  {
    label: "Bug",
    key: feedbackOptions.bug
  },
  {
    label: "New Feature",
    key: feedbackOptions.feature
  },
  {
    label: "Partnership",
    key:feedbackOptions.partnership
  }
];

const feedbackKeys = {
  email: "email",
  type: "type",
  description: "description",
};

const feedbackDefaultValues = {
  [feedbackKeys.email]: "",
  [feedbackKeys.type]: feedbackTypes[0].key,
  [feedbackKeys.description]: "",
};

const validations = {
  [feedbackKeys.description]: {
    minlength: 10,
    maxlength: 1000,
  }
}

const severityOptions = {
  success: 'success',
  error: 'error',
  info: 'info',
}

export { feedbackTypes, feedbackKeys, feedbackDefaultValues, feedbackOptions, validations, severityOptions };
