import { submitFeedback } from "./enums";

const CONSTANTS = {
  put: submitFeedback,
};

const put = `
    ${CONSTANTS.put} (description: $description, email: $email, type: $type)  {
      status
    }
`;

const source = {
  CONSTANTS,
  QUERIES: {
    put,
  }
};
  

export default source;
