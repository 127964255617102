import { Fragment, useReducer, useState } from "react";
import Form from "./Form";
import { useSubmitFeedbackMutation } from "../../services/graphql/api";
import { cleanHTML } from "../util/sanitize";
import { feedbackDefaultValues as defaultValues, feedbackKeys as keys, severityOptions } from "../util/constants";
import Alert from "./Alert";
import useRoleAuthentication from "../../Authentication/hooks/useRoleAuthentication";
import { checkFormBeforeSubmitting } from "../util/functions";
import { Mui } from '@osu/react-ui'

const updateForm = (state = defaultValues, action = {}) => {
  if (action.key) {
    return {
      ...state,
      [action.key]: action.value ?? "",
    };
  }

  return state;
};

const constructDefaults = (email = "") => {
  return {
    ...defaultValues,
    [keys.email]: email,
  };
};

function Feedback() {
  const [submitFeedback, results = {}] = useSubmitFeedbackMutation();
  const [overrideErrors, setOverrideErrors] = useState([]);
  const { isSuccess, isError, isLoading } = results
  const severity = isSuccess ? severityOptions.success : isError ? severityOptions.error : isLoading ? severityOptions.info : undefined
  const { user } = useRoleAuthentication();
  const [formValues, setFormValues] = useReducer(
    updateForm,
    constructDefaults(user?.email)
  );

  const submitForm = (form) => {
    if (form?.description) {
      const { email, type, description } = form;
      const sanitizedInput = cleanHTML(description)

      submitFeedback({
        email,
        type,
        description: sanitizedInput,
      });
    } 
  };

  const onChange = (event, key) => {
    return setFormValues({
      key,
      value: event?.target?.value ?? "",
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const original = formValues[keys.description];
    const formValuesPreppedForSubmission = {
      ...formValues,
      [keys.description]: cleanHTML(original),
    };

    const updatedDescription = formValuesPreppedForSubmission[keys.description]

    
    onChange(
      {
        target: {
          value: updatedDescription,
        },
      },
      keys.description
    );
    
    const errors = checkFormBeforeSubmitting(formValuesPreppedForSubmission)
    if(!errors.length) {
      setOverrideErrors([])
      submitForm(formValuesPreppedForSubmission);
    } else {
      setOverrideErrors(errors)
    }
  };

  return (
    <Fragment>
      <Alert overrideErrors={overrideErrors} severity={severity} />
      <Mui.Typography className="margin-bottom-3" variant="body2">
        Please use this form to let us know if something on the site is not working correctly, 
        if you have a suggestion for a feature you would like to see added, or general comments. 
        If you need assistance with your Ohio State account or need to contact the Buckeye Link office, 
        please visit <Mui.Link href="https://help.osu.edu/">help.osu.edu</Mui.Link>.
      </Mui.Typography>
      <Form 
        id="feedback-form" 
        readOnly={isSuccess || isLoading} 
        formValues={formValues} 
        onSubmit={onSubmit} 
        onChange={onChange} 
      />
    </Fragment>
  );
}

export default Feedback;
